<template>
  <div class="blind-details-container">
    <div class="collection">
      <div class="collection-top col-center">
        <!-- :style="'width:'+ imageWidth + ';height:' +  imageHeight +';margin: 0 21px;'" -->
        <div
          class="row-center"
          :style="'width:100%'"
        >
          <!-- <img
            class="collection-top-img"
            :src="collectionInfo.image"
          /> -->
          <!-- <goods-item
            :goodsType="'img'"
            :goodsSrc="collectionInfo.image"
          ></goods-item> -->
          <goods-item
            :goodsType="collectionInfo.cateId ? mediaType[collectionInfo.cateId] : ''"
            :goodsSrc="collectionInfo.image"
            :goodsSource="collectionInfo.sourceFile"
            :goodsPoster="collectionInfo.coverImg"
            :goodsBackground="collectionInfo.backgroundImg"
          ></goods-item>
        </div>

      </div>
      <div class="collection-infos">
        <p class="collection-top-name">{{ collectionInfo.boxSkuName }}</p>
        <div class="collection-id">编号:{{ collectionInfo.showId && collectionInfo.showId != '0' ? collectionInfo.showId : collectionInfo.uniqueCode }}</div>

      </div>
      <div class="collection-man row-between">
        <van-cell-group style="width: 100%">
          <van-field
            style="height:47px;"
            label="作者"
            :value="collectionInfo.creatorName"
            readonly
            autosize
          />
          <van-field
            style="height:47px;padding-top: 16px;"
            label="获得时间"
            :value="collectionInfo.receiveTime"
            readonly
            autosize
          />
        </van-cell-group>
      </div>

      <p class="collection-tip">提示：本数字藏品的知识产权或其他权益属发行方或权利人所有，除另行取得发行方或权利人授权外，您不得将数字藏品用于任何商业用途。请远离非理性炒作，防范欺诈风险。</p>
      <p class="copyright">区块链技术由智链2.0提供支持</p>
      <p style="margin-top:3.125rem;"></p>
    </div>
    <div class="collection-footer row-center">
      <div
        @click="openBox()"
        style="width:49vw;height: 100%;border-right: 1px solid #2D2D2D;"
        class="row-center"
      >
        <img :src="collectionInfo.status == 1 ? require('../assets/icon/blindBox/openBox.png') : require('../assets/icon/blindBox/no-openBox.png')" />
        <p :style="collectionInfo.status == 1 ? 'color: #ffffff;' : 'color: #666666;'">拆盲盒</p>
      </div>
      <div
        @click="donationCollection()"
        style="width:50vw"
        class="row-center"
      >
        <img :src="collectionInfo.status == 1 ? require('../assets/icon/collection-send.png') : require('../assets/icon/no-send.png')" />
        <p :style="collectionInfo.status == 1 ? 'color: #ffffff;' : 'color: #666666;'">转 赠{{ collectionInfo.status == 1 ? '' : '  (转赠中)' }}</p>
      </div>

    </div>
    <!-- 遮照 -->
    <div
      v-if="showBoxDetail"
      class="toast"
      @click="goBack()"
    ></div>
    <!-- 背景图 -->
    <div
      @click="showBoxDetail = false;"
      v-if="showBoxDetail"
      :style="'position: fixed;left:5vw;top:5vw;width: 90vw;height: 137.4vw;background-image: url('+ blindBoxBg +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% center;background-size: cover;background-color: transparent;border-radius: 8px;z-index:10001;'"
    >
      <div
        style="z-index:10003;position: fixed;top:33vw;left:18.4vw;"
        class="blind-img-box row-center"
      >
        <div :style="'width: 62.9vw;height: 62.9vw;background-image: url('+ blindCollection.relatedAssetsImage +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% center;background-size: 100% auto;background-color: transparent;border-radius: 8px;z-index:10001;'">
        </div>
      </div>
      <p class="blind-collection-name">{{ blindCollection.relatedAssetsName }}</p>
      <p class="blind-collection-id">ID:{{ blindCollection.showId != '0' ? blindCollection.showId : blindCollection.relatedAssetsUniqueCode }}</p>
      <div
        v-if="showBoxDetail"
        :style="windowHeight <= 560 ? 'position: fixed;top:13vw;margin-left: -5vw;width:90%;text-align:right;' : 'position: fixed;top:153.6vw;margin-left: -5vw;width:100%;text-align:center;'"
        style="z-index:10003;"
        @click="goBack()"
      >
        <img
          style="width:36px;height:36px;"
          src="../assets/icon/blindBox/close.png"
        />
      </div>
    </div>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <el-dialog
      title="确定要打开盲盒吗？"
      :visible.sync="openBlindVisible"
      @confirm="openBlind()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="openBlindVisible = false">取 消</el-button>
        <div
          @click="openBlind"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="$router.push('/resetPwd')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="donationErrorVisible"
      @confirm="donationErrorVisible = false"
      class="donation-dialog"
      center
    >
      <p class="donation-error-title">{{ donationErrorTitle }}</p>
      <p class="donation-error-tip">{{ donationErrorTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="donationErrorVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >点击右上角按钮，然后在弹出的菜单中，点击在浏览器打开，转赠</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import GoodsItem from '../components/GoodsItem.vue'
import Cookies from 'js-cookie'
import Vue from 'vue'
import { Field,CellGroup } from 'vant';

Vue.use(Field);
Vue.use(CellGroup);
import config from '../config'
import { Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
export default {
  components: {
    GoodsItem
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      openBlindVisible: false,
      blindBoxBg: require('../assets/icon/blindBox/open-box-bg.png'),
      blindCollection: {
        relatedAssetsImage: 'https://faramita-test.oss-cn-beijing.aliyuncs.com/files/956443471411675.png',
        relatedAssetsName: '牛年限定盲盒-哞哞牛',
        relatedAssetsUniqueCode: '101000014411156014'
      },
      showBoxDetail: false,
      browerOpenVisible: false,
      donationVisible: false,
      authVisible: false,
      donationErrorVisible: false,
      donationErrorTitle: '',
      donationErrorTip: '',
      imageWidth: '',
      imageHeight: '',
      collectionInfo: {
        image: '',
        name: '',
        skuNo: '',
        uname: '',
        uniqueCode: '',
        facImg: '',
        facName: '',
        issueTime: '',
        chainStatus: '',
      },
      hasRealname: false,
      mediaType: config.mediaType
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.imageWidth = this.imageHeight = (document.documentElement.clientWidth - 42) + 'px'
    localStorage.removeItem('uidOrPhopneType')
    this.getCollectionInfo()
    this.getMyInfo()
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.hasRealname = result.data.data.hasRealname
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    goBack () {
      this.showBoxDetail = false;
      if (Cookies.get('Device') == 'android') {
        // webview内返回上级页面
        var arr1 = JSON.parse(localStorage.getItem('fromUrlList')).filter((i,j) => j < (JSON.parse(localStorage.getItem('fromUrlList')).length - 1))
        this.$router.push(JSON.parse(localStorage.getItem('fromUrlList'))[(JSON.parse(localStorage.getItem('fromUrlList'))).length - 1])
        localStorage.setItem('fromUrlList',JSON.stringify(arr1))
      } else {
        // h5返回上级页面
        this.$router.go(-1);
      }
    },
    // 获取藏品信息
    getCollectionInfo () {
      api
        .post('assets/box/detail/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.collectionInfo = result.data.data
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取转赠藏品信息
    giftChooseEnd (id) {
      api
        .post('gift/choose/' + id + '?skuType=1')
        .then(result => {
          if (result.data.success) {
            this.$store.commit('SET_SELECTCOLLECTIONTAB','2')
            localStorage.removeItem('selectUniqueList')
            localStorage.setItem('selectCollectionTab',1)
            this.$router.push('/donation?id=' + this.$route.query.id + '&type=2')
          } else if (result.data.status == '6003') {
            this.authVisible = true
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.show = false
            this.donationErrorVisible = true
            this.donationErrorTitle = result.data.status != '10007' && result.data.status != '10008' ? result.data.msg : result.data.msg.split('\n')[0]
            this.donationErrorTip = result.data.status == '10007' || result.data.status == '10008' ? result.data.msg.split('\n')[1] : ''
          }
        })
    },
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    openBlind () {
      this.openBlindVisible = false
      api
        .post('assets/box/open/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.blindCollection = result.data.data
            this.showBoxDetail = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 开盲盒
    openBox () {
      if (this.collectionInfo.status == 1) {
        this.openBlindVisible = true
      }

    },
    // 藏品转赠
    donationCollection () {
      if (this.collectionInfo.status == 1) {
        if (Cookies.get('Device') == 'pico') {
          this.$toast({
            message: '请移步手机端进行转赠',
            icon: require('../assets/icon/toast-error.png'),
          });
        } else {
          // if (this.isWechat()) {
          //   this.browerOpenVisible = true
          // } else {
          this.giftChooseEnd(this.$route.query.id)
          // }

        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.collection-infos {
  width: 100%;
  // height: 5rem;
  background: url('../assets/img/store/store_info.png') 50% 50% no-repeat;
  background-size: 100% 100%;
  .collection-top-name {
    font-size: 1.1875rem;
    font-weight: bold;
    // color: #292929;

    color: #ffffff;
    height: 1.6875rem;
    line-height: 1.6875rem;
    margin: 0 1.25rem;
    padding-top: 1rem;
    width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // text-align: center;
  }

  .collection-id {
    // width: 9.6875rem;
    height: auto;
    padding: 0.5rem 1.25rem 1rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 200;
    // color: #292929;
    line-height: 1.0625rem;
    // background: #f2f2f2;
    border-radius: 0.125rem;
    word-break: break-all;

    color: #ffffff;
  }
}
.blind-details-container {
  // margin-top: 3.875rem;
  height: 100vh;
  width: 100%;
  z-index: 19;
  .collection {
    height: calc(100vh - 3.125rem);
    overflow: auto;
  }
  .collection-top {
    width: 100%;
    height: auto;
    min-height: 15.625rem;
    background: #d0d0d0;
    // padding-bottom: 1.1875rem;
  }

  // #090A0A
  .collection-man {
    margin: 0.625rem 2% 0 2%;
    width: 96%;
    height: auto;
    padding: 1rem 0;
    background: #333333;
    border-radius: 0.5rem;
    z-index: 20;
  }

  .collection-author {
    margin: 0.625rem 2% 0 2%;
    width: 96%;
    height: 4.1875rem;
    background: #ffffff;
    border-radius: 0.25rem;
  }

  .collection-man-title {
    width: 30%;
    height: 3.75rem;
    margin-left: 0.875rem;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #333333;
    line-height: 1.0625rem;
  }

  .collection-author-title {
    width: 30%;
    margin-left: 0.875rem;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #333333;
    line-height: 1.0625rem;
  }

  .collection-man-content {
    width: 70%;
    height: 3.75rem;
    margin-right: 1.875rem;
  }

  .collection-man-name {
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #333333;
    line-height: 1.375rem;
  }

  .collection-man-id {
    margin-top: 0.3125rem;
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.0625rem;
    word-break: break-all;
  }

  .collection-time {
    margin-top: 0.3125rem;
    width: 70%;
    height: auto;
    font-size: 0.75rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.0625rem;
    word-wrap: break-word;
  }

  .collection-top-img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }

  .van-cell-group {
    background: #333333;
    /deep/ .van-field__label {
      // color: #333333;
      color: #e6e6e6;
      font-size: 0.875rem;
      font-family: lantingheiweight;
    }
    /deep/ .van-field__body {
      input,
      textarea {
        color: #999999;
        font-size: 0.75rem;
        font-family: lantingheiweight;
      }
    }
    /deep/ .van-cell {
      background: #333333;
    }
    /deep/ .van-cell::after {
      border-bottom: 2px solid #3f3f3f;
    }
  }
  .copyright {
    font-weight: 500;
    color: #999999;
    margin-top: 1.1875rem;
    text-align: center;
    font-size: 14px;
  }
  .collection-footer {
    height: 3.125rem;
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: #080808;
    z-index: 1998;
    -webkit-overflow-scroll: touch;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    img {
      width: 1.25rem;
      height: auto;
      margin-right: 0.6875rem;
      z-index: 1999;
    }
    p {
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      z-index: 1999;
    }
    .collection-footer-tip {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #a9a9a9;
      line-height: 17px;
      z-index: 1999;
    }
  }
  .blind-collection-name {
    width: 80vw;
    text-align: center;
    font-size: 22px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: fixed;
    top: 105.7vw;
    left: 10vw;
  }
  .blind-collection-id {
    width: 80vw;
    text-align: center;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: fixed;
    top: 114.87vw;
    left: 10vw;
  }
  .toast {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000000;
    opacity: 0.75;
    top: 0;
    left: 0;
    z-index: 10000;
  }
}
</style>